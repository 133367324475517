import React from "react";

import "./index.css";
import Flag from "../../Assets/Images/Flag.svg";
import moment from "moment";

import { getLocalTime, getLocalOnlyDate } from "../../utils/LocalTimeFormat";

function RowBody({ medicineList, handleViewClick }) {
  const checkStatus = (item) => {
    if (item == 0) {
      return "Pending";
    } else if (item == -1) {
      return "Rejected";
    } else if (item == 1) {
      return "Accepted";
    }
  };

  return (
    <>
      <div className="rowList">
        <div className="boxSub" style={{ width: 120 }}>
          <div>
            <div className="flagView">
              {medicineList?.redAllert === 1 && (
                <img src={Flag} alt={"Goto icon"} className="calenderImg" />
              )}

              <div className="boxSub" style={{ display: "block", gap: "10px" }}>
                <div className="patientId">
                  {medicineList?.firstName} {medicineList?.lastName}
                </div>

                <div className="patientId">{medicineList?.patientCode}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="boxSub">
          <div className="patientId">{medicineList?.mobileNumber}</div>
        </div>

        <div className="boxSub">
          <p className="patientId">
            {moment(medicineList?.givenDate).format("DD/MM/YY")}
          </p>
        </div>

        <div className="boxSub">
          <p className="patientId">{checkStatus(medicineList?.isVerified)}</p>
        </div>

        <div className="boxSub">
          <button
            onClick={() =>
              handleViewClick(
                medicineList?.originalDoc,
                medicineList?.smartDoc,
                medicineList?.id,
                medicineList?.patientCode
              )
            }
            style={{
              color: "blue",
              textDecoration: "underline",
              backgroundColor: "white",
              border: "none",
              cursor: "pointer",
            }}
            className="patientId"
          >
            View
          </button>
        </div>
      </div>
    </>
  );
}

export default RowBody;
