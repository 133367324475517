import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import http from '../../../src/Redux/services/http-common.jsx';

function LabModal({ onClose, setLabData }) {
  const toast = useRef(null);
  const [selectedTests, setSelectedTests] = useState([]);
  const [newTest, setNewTest] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [testOptions, setTestOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(''); // ✅ Fix: Store user input

  useEffect(() => {
    setLoading(true);
    http.get(`${process.env.REACT_APP_CHE_BASEURL}DiagnosticsLabTests/list/filter?parentId=0&status=1`)
      .then(response => {
        const options = response.data.map(test => ({ label: test.name, value: test.name, code: test.code }));
        setTestOptions(options);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error('Error fetching test options:', error);
      });
  }, []);

  const searchTests = (event) => {
    const query = event.query.toLowerCase();
    setSearchValue(event.query); // ✅ Fix: Store user input
    const filtered = testOptions.filter(option => option.label.toLowerCase().includes(query));
    setFilteredOptions(filtered);
  };

  const handleAutoCompleteChange = (e) => {
    setSearchValue(e.value); // ✅ Fix: Allow users to type freely
  };

  const handleAutoCompleteSelect = (e) => {
    const selectedTest = e.value;
    if (!selectedTests.some(test => test.value === selectedTest.value)) {
      setSelectedTests([...selectedTests, selectedTest]);
    }
    setSearchValue(''); // ✅ Reset input field after selection
  };

  const handleAddCustomTest = () => {
    if (newTest.trim() && !selectedTests.some(test => test.label === newTest.trim())) {
      const customTest = { label: newTest.trim(), value: newTest.trim(), code: null };
      setSelectedTests([...selectedTests, customTest]);
      setNewTest('');
    }
  };

  const handleDeleteTest = (test) => {
    setSelectedTests(selectedTests.filter(item => item.value !== test.value));
  };

  const handleSave = () => {
    if (selectedTests.length === 0) {
      toast.current.show({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Please add at least one lab test!',
      });
      return;
    }

    setLabData(selectedTests);
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Lab added successfully!',
    });

    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <Dialog header="Add Lab Test" visible={isModalVisible} style={{ width: '400px' }} onHide={onClose}>
      <Toast ref={toast} />

      <div className="field">
        <label htmlFor="test-autocomplete" className="block mb-2">Test</label>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProgressSpinner style={{ width: '40px', height: '40px' }} />
          </div>
        ) : (
          <AutoComplete
            id="test-autocomplete"
            value={searchValue} // ✅ Fix: Store input text
            suggestions={filteredOptions}
            completeMethod={searchTests}
            field="label"
            dropdown
            placeholder="Select or search a test"
            onChange={handleAutoCompleteChange} // ✅ Allow manual input
            onSelect={handleAutoCompleteSelect} // ✅ Handle selection
            style={{ width: '100%' }}
          />
        )}
      </div>

      <div className="field mt-3">
        <span className="text-sm text-teal-600">If lab tests are not available in the list, please type it here.</span>
      </div>

      <div className="field mt-3 flex">
        <InputText
          value={newTest}
          onChange={(e) => setNewTest(e.target.value)}
          placeholder="Enter the Lab Test"
          style={{ flex: 1, marginRight: '10px' }}
        />
        <Button label="Add" icon="pi pi-plus" onClick={handleAddCustomTest} className="p-button-success" />
      </div>

      <div className="field mt-4">
        <h3 className="text-lg">Selected Lab Tests</h3>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {selectedTests.map((test, index) => (
            <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
              <span>{test.label} {test.code && <Tag value={test.code} severity="info" className="ml-2" />}</span>
              <Button
                icon="pi pi-trash"
                className="p-button-text p-button-danger"
                onClick={() => handleDeleteTest(test)}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="field mt-4">
        <Button label="Save" className="p-button-primary" style={{ width: '100%' }} onClick={handleSave} />
      </div>
    </Dialog>
  );
}

export default LabModal;
