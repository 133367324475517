import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from "primereact/toast";
import axios from "axios";
import http from "../../../src/Redux/services/http-common.jsx";

function MedicineModal({ setMedicineData }) {
  const toast = useRef(null); // Toast reference for notifications
  const [medicines, setMedicines] = useState([
    {
      id: 1,
      name: "",
      composition: "",
      quantity: 0,
      dosage: "",
      instructions: "",
      duration: "",
      type: "",
    },
  ]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [fullSuggestions, setFullSuggestions] = useState([]);

  const typeOptions = [
    { label: "Rx", value: "Rx" },
    { label: "NRx", value: "NRx" },
    { label: "XRx", value: "XRx" },
  ];

  const dosageOptions = [
    { label: "Select", value: "" },
    { label: "0-0-1", value: "0-0-1" },
    { label: "0-1-1", value: "0-1-1" },
    { label: "1-1-1", value: "1-1-1" },
    { label: "s.o.s.", value: "s.o.s." },
  ];

  const instructionsOptions = [
    { label: "None", value: "" },
    { label: "Before Meal", value: "Before Meal" },
    { label: "After Meal", value: "After Meal" },
  ];

  const durationOptions = Array.from({ length: 31 }, (_, i) => ({
    label: `${i} days`,
    value: `${i} days`,
  }));

  const addMedicine = () => {
    setMedicines([
      ...medicines,
      {
        id: medicines.length + 1,
        name: "",
        composition: "",
        code: "",
        quantity: 0,
        dosage: "",
        instructions: "",
        duration: "",
        type: "",
      },
    ]);
  };

  const deleteMedicine = (id) => {
    setMedicines(medicines.filter((medicine) => medicine.id !== id));
  };

  const fetchSuggestions = async (query) => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_CHE_BASEURL}DrugsInfo/list?drugName=${query}&userDefined=N`
      );
      if (response.data) {
        setFilteredSuggestions(response?.data.map((item) => item?.drugname));
        setFullSuggestions(response.data);
      }
    } catch (error) {
      console.error("Error fetching drug suggestions:", error);
    }
  };

  const handleAutoCompleteChange = (id, value) => {
    setMedicines(
      medicines.map((medicine) =>
        medicine.id === id ? { ...medicine, name: value } : medicine
      )
    );

    const selectedMedicine = fullSuggestions.find(
      (item) => item.drugname === value
    );
    if (selectedMedicine) {
      setMedicines((prevMedicines) =>
        prevMedicines.map((medicine) =>
          medicine.id === id
            ? {
                ...medicine,
                composition: selectedMedicine.composition,
                code: selectedMedicine.code,
              }
            : medicine
        )
      );
    }
  };

  const handleSave = () => {
    // Validation: Ensure all fields are filled
    const isValid = medicines.every(
      (medicine) =>
        medicine.name &&
        medicine.composition &&
        medicine.quantity > 0 &&
        medicine.dosage &&
        medicine.instructions &&
        medicine.duration &&
        medicine.type &&
        medicine.code
    );

    if (!isValid) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill all the fields before saving!",
      });
      return;
    }

    setMedicineData(medicines);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Medicine added successfully!",
    });
  };

  return (
    <div className="p-fluid">
      <Toast ref={toast} /> {/* Toast Notification */}
      <table
        className="medicine-table"
        style={{ width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr>
            <th>Medicine Name</th>
            <th>Composition</th>
            <th>Quantity</th>
            <th>Dosage</th>
            <th>Instructions</th>
            <th>Duration</th>
            <th>Type</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {medicines.map((medicine) => (
            <tr key={medicine.id} style={{ borderBottom: "1px solid #ddd" }}>
              <td>
                <AutoComplete
                  value={medicine.name}
                  suggestions={filteredSuggestions}
                  completeMethod={(e) => fetchSuggestions(e.query)}
                  onChange={(e) =>
                    handleAutoCompleteChange(medicine.id, e.value)
                  }
                  placeholder="Search/Add Medicine"
                />
              </td>
              <td>
                <InputText
                  value={medicine.composition}
                  readOnly
                  placeholder="Composition"
                />
              </td>
              <td>
                <InputNumber
                  value={medicine.quantity}
                  onValueChange={(e) =>
                    setMedicines(
                      medicines.map((m) =>
                        m.id === medicine.id ? { ...m, quantity: e.value } : m
                      )
                    )
                  }
                  min={1}
                />
              </td>
              <td>
                <Dropdown
                  value={medicine.dosage}
                  options={dosageOptions}
                  onChange={(e) =>
                    setMedicines(
                      medicines.map((m) =>
                        m.id === medicine.id ? { ...m, dosage: e.value } : m
                      )
                    )
                  }
                  placeholder="Select"
                />
              </td>
              <td>
                <Dropdown
                  value={medicine.instructions}
                  options={instructionsOptions}
                  onChange={(e) =>
                    setMedicines(
                      medicines.map((m) =>
                        m.id === medicine.id
                          ? { ...m, instructions: e.value }
                          : m
                      )
                    )
                  }
                  placeholder="Select"
                />
              </td>
              <td>
                <Dropdown
                  value={medicine.duration}
                  options={durationOptions}
                  onChange={(e) =>
                    setMedicines(
                      medicines.map((m) =>
                        m.id === medicine.id ? { ...m, duration: e.value } : m
                      )
                    )
                  }
                  placeholder="Select Duration"
                />
              </td>
              <td>
                <Dropdown
                  value={medicine.type}
                  options={typeOptions}
                  onChange={(e) =>
                    setMedicines(
                      medicines.map((m) =>
                        m.id === medicine.id ? { ...m, type: e.value } : m
                      )
                    )
                  }
                  placeholder="Select"
                />
              </td>
              <td>
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger"
                  onClick={() => deleteMedicine(medicine.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          marginTop: "30px",
        }}
      >
        <div>
          <Button
            label="Add Medicine"
            icon="pi pi-plus"
            className="p-button-success"
            onClick={addMedicine}
          />
        </div>
        <div>
          <Button
            label="Save Medicines"
            icon="pi pi-save"
            className="p-button-primary"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}

export default MedicineModal;
