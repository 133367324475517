import DatePicker from "react-datepicker";
import { useState, React, useRef, useEffect } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "../CarePlan/index.css";
import { Toast } from 'primereact/toast';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from 'primereact/dialog';
import SidebarEprescription from "./SidebarEprescription.jsx";
import Eprescription from "./Eprescription.jsx";
import moment from "moment";
import { Calendar } from "primereact/calendar";

function Index() {
    const toast = useRef(null);
    const [searchResults, setSearchResults] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [sideDialogue, setSideDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [sideDialogue1, setSideDialog1] = useState(false);
    const [selectedRow1, setSelectedRow1] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 10;

    const handleSearchSubmit = () => {
        let url = `${process.env.REACT_APP_BASEURL}PatientDocument/getAllDocuments?pageSize=${pageSize}&pageNumber=${pageNumber}&reportType=D01`;

        if (fromDate && toDate) {
            const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
            const formattedToDate = moment(toDate).format("YYYY-MM-DD");
            url += `&fromDate=${formattedFromDate}&toDate=${formattedToDate}`;
        }

        console.log("Fetching URL:", url);

        http.get(url)
            .then((res) => {
                console.log("Response Data:", res?.data?.data,res?.data?.data[0]?.totalSize);
                setSearchResults(res?.data?.data);
                setTotalRecords(res?.data?.data[0]?.totalSize || 0);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        handleSearchSubmit();
    }, [pageNumber]);

    return (
        <>
            <Toast ref={toast} />
            <div style={{ paddingTop: "20px", paddingBottom: "20px", display: "flex", flexDirection: "row", gap: "10px" }}>
                <div>
                    <Calendar
                        value={fromDate}
                        onChange={(e) => setFromDate(e.value)}
                        placeholder="Select From Date"
                        dateFormat="dd-mm-yy"
                        showIcon
                    />
                </div>
                <div>
                    <Calendar
                        value={toDate}
                        onChange={(e) => setToDate(e.value)}
                        placeholder="Select To Date"
                        dateFormat="dd-mm-yy"
                        showIcon
                    />
                </div>
                <div>
                    <button onClick={handleSearchSubmit} className="p-button p-component p-button-primary">Search</button>
                </div>
            </div>
            {searchResults.length === 0 ? (
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem", color: "blue" }} />
                </div>
            ) : (
                <DataTable 
                    value={searchResults} 
                    paginator 
                    rows={pageSize} 
                    totalRecords={totalRecords} 
                    lazy 
                    first={(pageNumber - 1) * pageSize}
                    onPage={(e) => setPageNumber(e.page + 1)}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                >
                    <Column sortable header="Patient Name" body={(rowData) => `${rowData.firstName} ${rowData.lastName}`} />
                    <Column sortable header="Mobile Number" field="mobile" />
                    <Column sortable header="Email" field="email" />
                    <Column
                        sortable
                        header="Prescription"
                        body={(rowData) => (
                            <a href={`${process.env.REACT_APP_IMG_BASEURL}${rowData.document}`} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                {rowData.document}
                            </a>
                        )}
                    />
                    <Column
                        header="Action"
                        body={(rowData) => (
                            rowData.isVerified === 1 && rowData.smartReportStatus === 1 && rowData.status === 1 ? (
                                <span style={{ color: "green", fontWeight: "bold" }}>Completed</span>
                            ) : (
                                <i className="fa fa-pencil-square-o" aria-hidden="true" onClick={() => setSelectedRow(rowData) || setSideDialog(true)} style={{ cursor: "pointer" }}></i>
                            )
                        )}
                    />
                    <Column
                        header="View Prescription"
                        body={(rowData) => (
                            rowData?.smartReportStatus ? 
                            <i className="fa fa-eye" aria-hidden="true" onClick={() => setSelectedRow1(rowData) || setSideDialog1(true)} style={{ cursor: "pointer" }}></i>
                            :""
                        )}
                    />
                </DataTable>
            )}
            <Dialog visible={sideDialogue} position="right" onHide={() => setSideDialog(false)}>
                <SidebarEprescription scheduledata={selectedRow} closeFun={() => setSideDialog(false)} />
            </Dialog>
            <Dialog visible={sideDialogue1} onHide={() => setSideDialog1(false)}>
                <Eprescription scheduledata={selectedRow1} closeFun={() => setSideDialog1(false)} />
            </Dialog>
        </>
    );
}
export default Index;
