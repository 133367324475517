import React, { useEffect, useState } from "react";
import http from "../../../src/Redux/services/http-common.jsx";

function Eprescription({ scheduledata, closeFun }) {
    const [datajson, setDatajson] = useState(null);

    useEffect(() => {
        if (!scheduledata?.patientCode || !scheduledata?.id) return;

        let url = `${process.env.REACT_APP_BASEURL}PatientDocument/getDocuments?reportType=D01&patientCodes=${scheduledata.patientCode}&order=DESC&id=${scheduledata.id}`;

        console.log("Fetching URL:", url);

        http.get(url)
            .then((res) => {
                const smartdocArray = res?.data?.data.map((item) => {
                    try {
                        return JSON.parse(item?.smartDoc);
                    } catch (error) {
                        console.error("Error parsing smartDoc:", error);
                        return null;
                    }
                });

                if (smartdocArray.length > 0) {
                    setDatajson(smartdocArray[0]); 
                }

                console.log("Parsed Data:", smartdocArray);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [scheduledata]);

    if (!datajson) {
        return <div>Loading Prescription...</div>;
    }

    const { firstName, lastName, mobile,docName, visitDate,chiefComplaint, symptoms, primaryDiagnosis, primarySymptoms, secondaryDiagnosis, secondarySymptoms, recommendation, patientDrugPrescriptionList, patientLabTestsList } = datajson.data;

    return (
        <div style={{ fontFamily: "Arial, sans-serif",padding:"10px", border: "1px solid #ccc",  margin: "auto" }}>
          

            <h3>{docName}</h3>
          
            <hr />

            {/* Patient Details */}
            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px", border: "1px solid #ccc" }}>
      <tbody>
        <tr style={{ borderBottom: "1px solid #ccc", background: "#f5f5f5" }}>
          <td style={{ padding: "8px", fontWeight: "bold", borderRight: "1px solid #ccc" }}>Name:</td>
          <td style={{ padding: "8px", borderRight: "1px solid #ccc" }}>{firstName} {lastName}</td>
          {/* <td style={{ padding: "8px", fontWeight: "bold", borderRight: "1px solid #ccc" }}>Age/Sex:</td>
          <td style={{ padding: "8px" }}></td> */}
          <td style={{ padding: "8px", fontWeight: "bold", borderRight: "1px solid #ccc" }}>Hospital:</td>
          <td style={{ padding: "8px" }}>eClinic</td>
        </tr>
        <tr>
          <td style={{ padding: "8px", fontWeight: "bold", borderRight: "1px solid #ccc" }}>Phone Number:</td>
          <td style={{ padding: "8px", borderRight: "1px solid #ccc" }}>{mobile}</td>
          {/* <td style={{ padding: "8px", fontWeight: "bold", borderRight: "1px solid #ccc" }}>Visit Type:</td>
          <td style={{ padding: "8px" , }}></td> */}
          <td style={{ padding: "8px", fontWeight: "bold", borderRight: "1px solid #ccc" }}>Visit Date:</td>
          <td style={{ padding: "8px"}}>{visitDate}</td>
        </tr>
      </tbody>
    </table>
            
            <h3>Chief Complaint:</h3>
            <p>{chiefComplaint || "N/A"}</p>

            <h3>Symptoms:</h3>
            <p>{symptoms || "N/A"}</p>

            {/* Diagnoses */}
            <h3>Primary Diagnosis:</h3>
            <p>ICD-10: {primarySymptoms || "N/A"}</p>
            <p>Description: {primaryDiagnosis || "N/A"}</p>

            {secondaryDiagnosis && (
                <>
                    <h3>Secondary Diagnosis:</h3>
                    <p>ICD-10: {secondarySymptoms || "N/A"}</p>
                    <p>Description: {secondaryDiagnosis}</p>
                </>
            )}

            {/* Medicines */}
            <h3>Medicines Prescribed:</h3>
            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px", border: "1px solid #ccc" }}>
                <thead>
                    <tr style={{ background: "#f4f4f4" }}>
                        <th style={{ padding: "8px", border: "1px solid #ddd" }}>#</th>
                        <th style={{ padding: "8px", border: "1px solid #ddd" }}>Medication</th>
                        <th style={{ padding: "8px", border: "1px solid #ddd" }}>Dosage</th>
                        <th style={{ padding: "8px", border: "1px solid #ddd" }}>Qty</th>
                        <th style={{ padding: "8px", border: "1px solid #ddd" }}>Instruction</th>
                    </tr>
                </thead>
                <tbody>
                    {patientDrugPrescriptionList?.map((drug, index) => (
                        <tr key={index}>
                            <td style={{ padding: "8px", border: "1px solid #ddd" }}>{index + 1}</td>
                            <td style={{ padding: "8px", border: "1px solid #ddd" }}>{drug.drugName} ({drug.composition})</td>
                            <td style={{ padding: "8px", border: "1px solid #ddd" }}>{drug.dosage}</td>
                            <td style={{ padding: "8px", border: "1px solid #ddd" }}>{drug.quantity}</td>
                            <td style={{ padding: "8px", border: "1px solid #ddd" }}>{drug.afterBeforeFood}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Lab Tests */}
            {patientLabTestsList?.length > 0 && (
                <>
                    <h3>Lab Tests:</h3>
                    <ul>
                        {patientLabTestsList.map((test, index) => (
                            <li key={index}>{test.labTestName || test.name}</li>
                        ))}
                    </ul>
                </>
            )}

            {/* Recommendation */}
            <h3>Recommendation:</h3>
            <p>{recommendation || "N/A"}</p>

            <div style={{ marginTop: "10px" }}>
        <h3>Disclaimer :</h3>
        <ul style={{ marginTop: "5px", paddingLeft: "20px", lineHeight: "1.5" }}>
          <li>The information and advice provided here is provisional in nature as it is based on the limited information made available by the patient</li>
          <li>The patient is advised to visit in person for thorough examination at the earliest</li>
          <li>The information is confidential in nature and for recipient’s use only</li>
          <li>The Prescription is generated on a Teleconsultation</li>
          <li>Not valid for medico-legal purpose</li>
        </ul>
      </div>
        </div>
    );
}

export default Eprescription;
