import React, { useState, useRef } from "react";
import RowBody from "./RowBody";
import Loader from "../../components/Loader/Loader";
import { Dialog } from "primereact/dialog";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Button } from "primereact/button";
import http from "../../../src/Redux/services/http-common.jsx";
import { data } from "autoprefixer";
import { Toast } from "primereact/toast";
// import { Document, Page, pdfjs } from "react-pdf";
function MedicineOrderList({
  medicineList,
  doctorConsultation,
  isLoader,
  fetchList,
}) {
  console.log(medicineList, "cfghjklhbjkmll");
  const toast = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [smartDoc, setSmartDoc] = useState({
    id: "",
    smartDoc: [],
    patientCode: "",
  });
  // const [smartDoc, set]
  const handleViewClick = (originalDoc, smartDoc, id, patientCode) => {
    setFileUrl(originalDoc);
    let format = JSON.parse(smartDoc);
    setSmartDoc({
      id,
      smartDoc: format[0].data,
      patientCode: patientCode,
    });
    setIsVisible(true);
    if (originalDoc) {
      const extension = originalDoc.split(".").pop().toLowerCase();
      if (["png", "jpg", "jpeg", "gif", "webp"].includes(extension)) {
        setFileType("image");
      } else if (extension === "pdf") {
        setFileType("pdf");
      } else {
        setFileType("unknown");
      }
    }
  };

  console.log(smartDoc, "sdihvdsihvsdouvdsvds");

  const handleInputChange = (index, field, value) => {
    const updatedData = smartDoc.smartDoc.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setSmartDoc({ ...smartDoc, smartDoc: updatedData });
  };

  const handleAccept = () => {
    let payload = {
      id: smartDoc.id,
      isVerified: 1,
      patientCode: smartDoc.patientCode,
    };
    http
      .put(
        `${process.env.REACT_APP_BASEURL}backoffice/patientDocument/updateDoc/`,
        payload
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Status Updated",
          life: 3000,
        });
        fetchList();
      });
  };

  const handleReject = () => {
    let payload = {
      id: smartDoc.id,
      isVerified: -1,
      patientCode: smartDoc.patientCode,
    };
    http
      .put(
        `${process.env.REACT_APP_BASEURL}backoffice/patientDocument/updateDoc/`,
        payload
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Status Updated",
          life: 3000,
        });
        fetchList();
      });
  };

  const handleUpdate = () => {
    let data = {
      data: smartDoc.smartDoc,
    };
    let payload = {
      id: smartDoc.id,
      smartDoc: JSON.stringify([data]),
      patientCode: smartDoc.patientCode,
    };
    console.log(payload, "payloadpayload");
    http
      .put(
        `${process.env.REACT_APP_BASEURL}backoffice/patientDocument/updateDoc/`,
        payload
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Updated Successfully",
          life: 3000,
        });
        fetchList();
      });
  };

  const footerContent = () => {
    return (
      <div style={{ marginTop: 10 }}>
        <Button label="Update" severity="warning" onClick={handleUpdate} />
        <Button label="Accept" severity="success" onClick={handleAccept} />
        <Button label="Reject" severity="danger" onClick={handleReject} />
      </div>
    );
  };

  return (
    <div className="listContainer">
      <Toast ref={toast} />
      <div className="listBorder">
        {doctorConsultation.map((item, i) => (
          <div key={i}>
            <p className="listTitle1">{item} </p>
          </div>
        ))}
      </div>
      <div className="boxMain">
        {medicineList?.map((res) => (
          <RowBody medicineList={res} handleViewClick={handleViewClick} />
        ))}

        {isLoader != false && <Loader />}
      </div>
      <Dialog
        header="Header"
        visible={isVisible}
        style={{ width: "95vw" }}
        onHide={() => {
          if (!isVisible) return;
          setIsVisible(false);
        }}
        footer={footerContent}
      >
        <Splitter>
          <SplitterPanel className="flex align-items-center justify-content-center">
            {fileType === "image" ? (
              <img
                src={fileUrl}
                alt="File Preview"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            ) : fileType === "pdf" ? (
              <div style={{ height: "100%", overflow: "auto" }}>
                {/* <Document file={fileUrl} onLoadError={(err) => console.error(err)}>
              <Page pageNumber={1} />
            </Document> */}
                <iframe
                  src={fileUrl}
                  style={{ minHeight: "100%", width: "100%" }}
                />
              </div>
            ) : (
              <p>Unsupported file format</p>
            )}
          </SplitterPanel>
          <SplitterPanel className="flex align-items-center justify-content-center">
            <table
              border="1"
              cellPadding="10"
              cellSpacing="0"
              style={{
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "center",
                backgroundColor: "white",
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#4CAF50", color: "white" }}>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Unit</th>
                  <th>Reference Range</th>
                  <th>Flag</th>
                </tr>
              </thead>
              <tbody>
                {smartDoc?.smartDoc?.map((row, index) => (
                  <tr
                    style={{
                      backgroundColor: index % 2 === 0 ? "#e8f5e9" : "#f1f8e9",
                      transition: "background-color 0.3s ease",
                    }}
                    key={index}
                  >
                    <td style={{ fontWeight: "bold", color: "#388E3C" }}>
                      {" "}
                      <input
                        type="text"
                        value={row.name}
                        onChange={(e) =>
                          handleInputChange(index, "name", e.target.value)
                        }
                        style={{
                          padding: "5px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={row.value}
                        onChange={(e) =>
                          handleInputChange(index, "value", e.target.value)
                        }
                        style={{
                          padding: "5px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          width: "80px",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={row.unit}
                        onChange={(e) =>
                          handleInputChange(index, "unit", e.target.value)
                        }
                        style={{
                          padding: "5px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          width: "100px",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={row.reference_range}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "reference_range",
                            e.target.value
                          )
                        }
                        style={{
                          padding: "5px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          width: "100px",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={row.flag}
                        onChange={(e) =>
                          handleInputChange(index, "flag", e.target.value)
                        }
                        style={{
                          padding: "5px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          width: "80px",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </SplitterPanel>
        </Splitter>
      </Dialog>
    </div>
  );
}

export default MedicineOrderList;
