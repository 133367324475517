import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import http from "../../../src/Redux/services/http-common.jsx";
import "./labcart.css";
import MembershipStatus from "./MembershipStatus.jsx";
import AddressBook from "./AddressBook.jsx";
import moment from "moment";

const LabCartPage = ({
  scheduledata,
  closeFun,
  isAddressAdded,
  selectedAddress,
  setSelectedAddress = () => {},
  setIsAddressAdded = () => {},
}) => {
  const [pincode, setPincode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [labCircle, setLabCircle] = useState("");
  const [cart, setCart] = useState([]);
  const [hospitalName, setHospitalName] = useState({
    labpartnername: "",
    labtestType: "",
    labstartTime: "",
    labEndTime: "",
  });
  
  const [isMembershipApplied, setIsMembershipApplied] = useState(false);
  const [totalcartsummary, setTotalcartsummary] = useState();
  const toast = React.useRef(null);
  const [cartId, setCartId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [totalCartSummary, setTotalCartSummary] = useState({
    totalAmount: 0,
    paidAmount: 0,
  });
  const [locationid, setLocationid] = useState({
    locationId: "",
    hospitalname: "",
  });
  const [userName, setUserName] = useState("")

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    console.log(userData?.code,"edrtghj")
    setUserName(userData?.code)
   
}, [userName])

 
  const [selectedDate, setSelectedDate] = useState(new Date()); 
  const [selectedTime, setSelectedTime] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Generate next 7 days for date selection
  const dates = Array.from({ length: 7 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + i);
    return {
      date,
      label: i === 0 ? "Today" : i === 1 ? "Tomorrow" : date.toLocaleDateString("en-US", { weekday: "short" }),
      displayDate: date.toLocaleDateString("en-US", { month: "short", day: "numeric" }),
    };
  });





  const generateTimeSlots = (selectedDate) => {
    let slots = [];
    const now = new Date();
    const currentHour = now.getHours();
    const disableUntil = currentHour + 4;
  
    // Ensure selectedDate is valid, otherwise default to today
    if (!selectedDate) {
      selectedDate = new Date();
    }
  
    // Convert selectedDate to YYYY-MM-DD format
    const today = now.toISOString().split("T")[0];
    const selectedDateString = selectedDate.toISOString().split("T")[0];
  
    for (let hour = 6; hour <= 18; hour++) {
      let ampm = hour < 12 ? "AM" : "PM";
      let formattedHour = hour > 12 ? hour - 12 : hour;
      let timeSlot = `${formattedHour}:00 ${ampm}`;
  
      // Disable only today's past and next 4 hours, but allow future days
      let isDisabled = false;
      if (selectedDateString === today) {
        isDisabled = hour < currentHour || hour < disableUntil;
      }
  
      slots.push({ time: timeSlot, disabled: isDisabled });
    }
    return slots;
  };

  const generateTimeSlotsRadiology = (selectedDate, labstartTime, labEndTime) => {
    let slots = [];
  
    if (!labstartTime || !labEndTime) {
      return slots;
    }
  
    // Convert labstartTime and labEndTime to Date objects
    let startTime = new Date(`1970-01-01T${labstartTime}`);
    let endTime = new Date(`1970-01-01T${labEndTime}`);
    let now = new Date();
    let currentHour = now.getHours();
    let today = new Date().toISOString().split("T")[0];
    let selectedDateString = selectedDate.toISOString().split("T")[0];
  
    while (startTime < endTime) {
      let hour = startTime.getHours();
      let ampm = hour < 12 ? "AM" : "PM";
      let formattedHour = hour % 12 || 12; // Convert to 12-hour format
      let timeSlot = `${formattedHour} ${ampm}`;
  
      // Disable past time slots for the current day
      let isDisabled = selectedDateString === today && hour <= currentHour;
  
      slots.push({ time: timeSlot, disabled: isDisabled });
  
      // Increment by 1 hour
      startTime.setHours(startTime.getHours() + 1);
    }
  
    return slots;
  };
  

  // useEffect(() => {
  //   // Determine the initial date (e.g., today)
  //   const initialDate = new Date();
  
  //   // Generate available time slots for the initial date
  //   const availableTimeSlots =
  //     hospitalName?.labtestType === "Pathology"
  //       ? generateTimeSlots(initialDate)
  //       : generateTimeSlotsRadiology(
  //           initialDate,
  //           hospitalName?.labstartTime,
  //           hospitalName?.labEndTime
  //         );
  
  //   // Find the first available (non-disabled) time slot
  //   const firstAvailableSlot = availableTimeSlots.find(
  //     (slot) => !slot.disabled
  //   );
  
  //   // Set the initial selected date and time
  //   setSelectedDate(initialDate);
  //   setSelectedTime(firstAvailableSlot ? firstAvailableSlot.time : null);
  // }, [hospitalName]);
  
  

  useEffect(() => {
    if (scheduledata?.patientId) {
      fetchCartList();
    }
  }, [scheduledata?.patientId]);

  const fetchSuggestions = async (query, circle) => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?freeTextSearch=${query}&source=b2c&labCircle=${circle}`
      );
      setSuggestions(response?.data || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value.length > 2 && labCircle) {
      fetchSuggestions(value, labCircle);
    } else {
      setSuggestions([]);
    }
  };

  const checkServiceAvailability = async () => {
    if (!pincode) {
      toast.current.show({
        severity: "warn",
        summary: "Validation",
        detail: "Please enter a pincode.",
      });
      return;
    }

    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkDiagnoServiceAvailabilityCircle?pinCode=${pincode}`
      );
      const circle = response?.data;

      if (circle) {
        setLabCircle(circle);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `Service available for lab circle: ${circle}`,
        });
      } else {
        setLabCircle("");
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "No lab circle available for this pincode.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong while checking service availability.",
      });
    }
  };

  const selectSuggestion = (suggestion) => {
    setSearchText(suggestion.name);
    setSuggestions([]);
  };

  const addToCart = async (labTest) => {
    console.log(labTest, "Adding to cart");

 
   
    if (cart.length > 0 && locationid?.locationId && labTest.locationId !== locationid.locationId) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select this test from the same lab or replace the tests in your cart.",
        life: 3000,
      });
      return; 
    }

    const isTestAlreadyInCart = cart.some((item) => item.labTestName === labTest.labTestName);

    if (isTestAlreadyInCart) {
      toast.current.show({
        severity: "warn",
        summary: "Duplicate Test",
        detail: "This test is already in your cart.",
        life: 3000,
      });
      return; 
    }
  
    try {
      setHospitalName({
        labpartnername: labTest?.labName ||"",
        labtestType: labTest?.testType || "",
        labstartTime: labTest?.labStartTime ||"",
        labEndTime: labTest?.labEndTime ||" ",
      });
    
      const payload = {
        patientId: scheduledata?.patientId,
        labTestCode: labTest.labTestCode,
        amount: labTest.amount,
        hospitalId: labTest.labId,
        locationId: labTest.locationId,
        labTestName: labTest.labTestName,
        labTestType: labTest.testType,
        sourceChannel: "B2C",
      };
  
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}cart/addToCart/labtest`,
        payload
      );
  
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Added to Cart",
          detail: `${labTest.labTestName} has been added to the cart.`,
        });
        setSuggestions([]);
        fetchCartList();
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Failed",
          detail: "Unable to add the lab test to the cart.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong while adding to the cart.",
      });
    }
  };
  
console.log(hospitalName,"hospitalnnhhgg")
  const deleteCartItem = async (id) => {
    console.log(id, "gtfgtg");
    try {
      const response = await http.delete(
        `${process.env.REACT_APP_BASEURL}cart/deleteLabtest?Id=${id}`
      );

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Item Deleted",
          detail: "The item has been successfully removed from the cart.",
        });
        fetchCartList();
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Failed",
          detail: "Unable to delete the item.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong while deleting the item.",
      });
    }
  };
  const fetchCartList = async () => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}cart/list?patientId=${scheduledata?.patientId}`
      );

      const cartId = response?.data?.cartId;
      console.log(cartId, "Cart ID");
      setCartId(cartId);

      const cartData =
        response?.data?.labtestCart?.patientLabTestsOrderDetailsList || [];
      const updatedCart = cartData.map((item) => ({
        id: item.id,
        labTestName: item?.labTestName,
        labName: item?.drugCategory,
        totalAmount: item?.amount,
        membershipCode: item?.membershipCode,
        discountAmount: item?.discountAmount,
        labTestType:item?.labTestType
    
      }));
      const labTestTypes = updatedCart.map(item => item.labTestType);
   
    
      setHospitalName({
      labtestType:labTestTypes[0]
      });
    
      if (Array.isArray(labTestTypes) && labTestTypes.includes("Radiology")) {
        setHospitalName({
          labstartTime: response?.data?.labtestCart?.labStartTime ?? "",
          labEndTime: response?.data?.labtestCart?.labEndTime ?? "",
        });
      }
     
      setCart(updatedCart);
      const totalAmount = response?.data?.labtestCart?.totalAmount || 0;
      const paidAmount = response?.data?.labtestCart?.paidAmount || 0;

      setTotalCartSummary({ totalAmount, paidAmount });

      const locationid = response?.data?.labtestCart?.locationId;
      const hospitalName = response?.data?.labtestCart?.hospitalName;

      setLocationid({
        locationId: locationid || "",
        hospitalname: hospitalName || "",
      });

      if (!cartId || cartData.length === 0) {
        setCart([]);
        setIsMembershipApplied(false);
        return;
      }

      console.log(cartData, "Cart Data");

      const discountAmount = cartData.reduce(
        (acc, item) => acc + (item.discountAmount || 0),
        0
      );
      console.log(cartData, totalAmount, discountAmount, "Membership Applied");
      if (discountAmount) {
        console.log("Membership Applied");
        setIsMembershipApplied(true);
      } else {
        setIsMembershipApplied(false);
      }

      toast.current.show({
        severity: "info",
        summary: "Cart Updated",
        detail: "Cart has been updated with the latest items.",
      });
    } catch (error) {
      console.error("Error fetching cart list:", error);
    }
  };

  const calculateTotalAmount = () => {
    return cart.reduce((total, item) => total + item.totalAmount, 0);
  };

  console.log(moment(selectedDate).format("YYYY-MM-DD"),selectedTime, "esdffggg");

  const submitCart = async () => {
if (!selectedDate && !selectedTime){
  toast.current.show({
    severity: "warn",
    summary: "preferdate and time",
    detail: "Please select preferred date and time",
    life: 3000,
  });
}
    try {
      const pinCode = localStorage.getItem("selectedPinCode");
  
      if (!pinCode) {
        console.error("PinCode is not set. Please select an address.");
        toast.current.show({
          severity: "warn",
          summary: "Address Missing",
          detail: "Please select an address to proceed.",
          life: 3000,
        });
        return;
      }
  
      // Check service availability
      const serviceCheckResponse = await http.get(
        `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkDiagnoServiceAvailability?locationId=${locationid?.locationId}&pinCode=${pinCode}`
      );
  
      console.log(
        serviceCheckResponse?.data?.data,
        serviceCheckResponse?.data?.status,
        "fgthjn"
      );
  
      if (serviceCheckResponse?.data?.status === 400) {
        console.error(
          "Service not available for the selected pinCode:",
          pinCode
        );
        toast.current.show({
          severity: "warn",
          summary: "Service Unavailable",
          detail: `Service is not available for the selected PinCode: ${pinCode}`,
          life: 3000,
        });
        return;
      }
  
      if (cart.length === 0) {
        console.error("No medicine is present in cart:");
        toast.warn(`Please Add Lab test in cart To Proceed`, {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }
  
      
      const payload = {
        preferDateTime: moment(selectedDate).format("YYYY-MM-DD 00:00:00") ,
        preferTime: selectedTime,
        modifiedBy: userName,
      };
  
      const response1 = await http.put(
        `${process.env.REACT_APP_BASEURL}PatientLabTestsOrderDetails/update-preferred-time/${cartId}`,
        payload
      );
  
      console.log(response1, "Preferred time update response");
  
      if (response1?.status !== 200) {
        console.error("Failed to update preferred time.");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to update preferred time. Please try again.",
          life: 3000,
        });
        return; 
      }
  
     
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}newPatientMedicine/saveWhatsappUploadedPrescription?cartId=${cartId}&docName=${scheduledata?.photoName}&id=${scheduledata?.id}`
      );
  
      if (response) {
        const paymentPayload = {
          firstname: scheduledata?.firstName,
          email: scheduledata?.email,
          phone: scheduledata?.phoneNumber,
          productinfo: "cartpayment",
          cartid: cartId,
          patientId: scheduledata?.patientId,
          amount: totalCartSummary.paidAmount,
        };
  
        const paymentResponse = await http.post(
          `${process.env.REACT_APP_BASEURL}api/payment/generateAndSend`,
          paymentPayload
        );
  
        fetchCartList();
        closeFun();
  
        toast.current.show({
          severity: "success",
          summary: "Cart Updated",
          detail: "Cart has been updated successfully!",
          life: 3000,
        });
  
        console.log(paymentResponse, "Response from the second API");
      }
    } catch (error) {
      console.error("Error during cart submission:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while submitting the cart. Please try again later.",
        life: 3000,
      });
    }
  };
 
  


console.log(cart,"ftggh")
  

  return (
    <div className="container">
      <Toast ref={toast} />
      <div className="form-section">
        <label className="form-label">
          Check Diagnostic Service Availability
        </label>
        <div className="input-group">
          <InputText
            id="pincode"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            placeholder="Enter Pincode"
          />
          <Button
            label="Check Availability"
            icon="pi pi-check"
            className="button-primary"
            onClick={checkServiceAvailability}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: isAddressAdded ? "#e6f7e6" : "#fbe9e9",
          color: isAddressAdded ? "#155724" : "#721c24",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {isAddressAdded && selectedAddress ? (
            <div className="address-info">
              <strong>{selectedAddress.name}</strong>
              <p>
                {selectedAddress.address1} {selectedAddress.address2},{" "}
                {selectedAddress.city}, {selectedAddress.state} -{" "}
                {selectedAddress.pinCode}
              </p>
            </div>
          ) : (
            <label
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Address is not Added
            </label>
          )}
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "8px",padding:"10px" }}
          onClick={() => setIsVisible(true)}
        >
          <button
            style={{
              padding: "5px 10px",
              border: "none",
              borderRadius: "4px",
              color: "#e9e9ec",
              backgroundColor: "green",
              border: "none",
              cursor: "pointer",
            }}
          >
            Select/Add Address
          </button>
        </div>
      </div>
      <div className="form-section">
        <label className="form-label">Search for Lab Tests</label>
        <div className="input-group">
          <InputText
            value={searchText}
            onChange={(e) => handleSearchChange(e)}
            placeholder="Search for lab tests"
            disabled={!labCircle}
          />
          {/* <Button
        label="Search"
        icon="pi pi-search"
        className="button-primary"
        onClick={() => fetchSuggestions(searchText, labCircle)}
      /> */}
        </div>
        {suggestions.length > 0 && (
          <div className="suggestions-dropdown">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => addToCart(suggestion)}
              >
                <strong>{suggestion.labTestName}</strong>
                <div>{suggestion.labName}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <MembershipStatus
        patientId={scheduledata?.patientId}
        cartId={cartId}
        isMembershipApplied={isMembershipApplied}
        fetchCartList={fetchCartList}
        type={"lab"}
      />
      <div className="cart-section">
        {cart.length > 0 ? (
          <ul className="cart-list">
            {cart.map((item, index) => (
              <li key={index} className="cart-item">
                <div>
                  <strong>{item.labTestName}</strong>
                  <p>{hospitalName?.labpartnername}</p>
                  {isMembershipApplied === true ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#999",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹{item.totalAmount}
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        ₹{item.discountAmount}
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#999",
                          fontWeight: "bold",
                        }}
                      >
                        ₹{item.totalAmount}
                      </span>
                    </div>
                  )}
                </div>
                <Button
                  icon="pi pi-trash"
                  className="button-danger"
                  onClick={() => deleteCartItem(item.id)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p>Your cart is empty.</p>
        )}

        {hospitalName?.labtestType  =="Pathology" ? 
  <div>
      {/* Date Selection */}
      <div className="date-selection">
        <button 
          onClick={() => setCurrentIndex((prev) => Math.max(0, prev - 1))}
          disabled={currentIndex === 0}
        >
          {"<"}
        </button>

        {dates.slice(currentIndex, currentIndex + 2).map((day, index) => (
          <button
            key={index}
            className={selectedDate.toDateString() === day.date.toDateString() ? "date-btn active" : "date-btn"}
            onClick={() => setSelectedDate(day.date)}
          >
            <strong>{day.label}</strong>
            <br />
            {day.displayDate}
          </button>
        ))}

        <button 
          onClick={() => setCurrentIndex((prev) => Math.min(dates.length - 2, prev + 1))}
          disabled={currentIndex >= dates.length - 2}
        >
          {">"}
        </button>
      </div>

      {/* Time Slot Selection */}
      <div className="time-slot-container">
        {generateTimeSlots(selectedDate)?.map((slot, index) => (
          <button
            key={index}
            className={`time-slot ${selectedTime === slot.time ? "active" : ""} ${
              slot.disabled ? "disabled" : ""
            }`}
            onClick={() => !slot.disabled && setSelectedTime(slot.time)}
            disabled={slot.disabled}
          >
            {slot.time}
          </button>
        ))}
      </div>

      <div className="selected-details">
        <h3>Selected Date & Time:</h3>
        <p><strong>Date:</strong> {selectedDate.toLocaleDateString("en-US", { weekday: "long", month: "long", day: "numeric" })}</p>
        <p><strong>Time:</strong> {selectedTime || "Not selected"}</p>
      </div>
    </div>
    :
    <div>
    {/* Date Selection */}
    <div className="date-selection">
      <button 
        onClick={() => setCurrentIndex((prev) => Math.max(0, prev - 1))}
        disabled={currentIndex === 0}
      >
        {"<"}
      </button>
  
      {dates.slice(currentIndex, currentIndex + 2).map((day, index) => (
        <button
          key={index}
          className={`date-btn ${selectedDate.toDateString() === day.date.toDateString() ? "active" : ""}`}
          onClick={() => setSelectedDate(day.date)}
        >
          <strong>{day.label}</strong>
          <br />
          {day.displayDate}
        </button>
      ))}
  
      <button 
        onClick={() => setCurrentIndex((prev) => Math.min(dates.length - 2, prev + 1))}
        disabled={currentIndex >= dates.length - 2}
      >
        {">"}
      </button>
    </div>
  
    {/* Time Slot Selection */}
    <div className="time-slot-container">
      {generateTimeSlotsRadiology(selectedDate, hospitalName?.labstartTime, hospitalName?.labEndTime)?.map((slot, index) => (
        <button
          key={index}
          className={`time-slot ${selectedTime === slot.time ? "active" : ""} ${
            slot.disabled ? "disabled" : ""
          }`}
          onClick={() => !slot.disabled && setSelectedTime(slot.time)}
          disabled={slot.disabled}
        >
          {slot.time}
        </button>
      ))}
    </div>
  
    <div className="selected-details">
      <h3>Selected Date & Time:</h3>
      <p><strong>Date:</strong> {selectedDate.toLocaleDateString("en-US", { weekday: "long", month: "long", day: "numeric" })}</p>
      <p><strong>Time:</strong> {selectedTime || "Not selected"}</p>
    </div>
  </div>
  
    }

        {/* Cart Summary Section */}
        <div
          style={{
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        >
          <h4
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginBottom: "10px",
            }}
          >
            Cart Summary
          </h4>
          <p style={{ marginBottom: "5px" }}>
            Subtotal:{" "}
            <span style={{ fontWeight: "bold" }}>
              ₹{totalCartSummary.totalAmount}
            </span>
          </p>
          <p style={{ marginBottom: "5px" }}>
            Discount:{" "}
            <span style={{ fontWeight: "bold" }}>
              ₹
              {(
                totalCartSummary.totalAmount - totalCartSummary.paidAmount
              ).toFixed(2)}
            </span>
          </p>
          <p style={{ marginBottom: "5px" }}>
            Paid Amount:{" "}
            <span style={{ fontWeight: "bold" }}>
              ₹{totalCartSummary.paidAmount.toFixed(2)}
            </span>
          </p>
        </div>

        <div style={{ marginTop: "5px", textAlign: "right" }}>
          <Button
            label="Submit"
            icon="pi pi-check"
            className={isAddressAdded && selectedDate && selectedTime && cart.length > 0 ? "button-success" : "btn-secondary"}
            onClick={submitCart}
            disabled={!(isAddressAdded && selectedDate && selectedTime && cart.length > 0)  }
          />
        </div>
      </div>

      <AddressBook
        patientId={scheduledata?.patientId}
        isVisible={isVisible}
        onHide={() => setIsVisible(false)}
        cartId={cartId}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        setIsAddressAdded={setIsAddressAdded}
      />
    </div>
  );
};

export default LabCartPage;
