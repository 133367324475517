import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import MedicineModal from "./MedicineModal";
import LabModal from "./LabModal";
import { Dialog } from "primereact/dialog";
import http from "../../../src/Redux/services/http-common.jsx";
import { Toast } from "primereact/toast";

function SidebarEprescription({ scheduledata, closeFun }) {
  const toast = useRef(null);
  const {
    document,
    firstName,
    lastName,
    mobile,
    patientCode,
    documentType,
    title,
  } = scheduledata;

  const [formData, setFormData] = useState({
    chiefComplaint: "",
    doctor: "",
    date: new Date().toISOString().split("T")[0],
    primary: "",
    primaryICD: "",
    secondary: "",
    secondaryICD: "",
    symptoms: "",
    recommendation: "",
  });

  const [primaryOptions, setPrimaryOptions] = useState([]);
  const [icdCodeOptions, setIcdCodeOptions] = useState([]);
  const [secondaryOptions, setSecondary] = useState([]);
  const [submitData, setSubmitData] = useState({
    patientDrugPrescriptionList: [],
    patientLabTestsList: [],
  });
  const [activeModal, setActiveModal] = useState(null);

  console.log(submitData, scheduledata, "submitdatagv");

  const handleMedicineData = (data) => {
    setSubmitData((prev) => ({ ...prev, patientDrugPrescriptionList: data }));
  };

  const handleLabData = (data) => {
    setSubmitData((prev) => ({ ...prev, patientLabTestsList: data }));
  };
  useEffect(() => {
    const fetchPrimaryOptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_URL}Diagnosis/list?termSetId=ICD10&codeLevel=1&status=1`
        );
        console.log(response, "ggggyyuu");

        if (response.data && Array.isArray(response.data)) {
          const options = response?.data?.map((item) => ({
            label: item.description,
            value: item.description,
            termCode: item.termCode,
          }));
          setPrimaryOptions(options);
          setSecondary(options);
        }
      } catch (error) {
        console.error("Error fetching primary dropdown options:", error);
      }
    };

    fetchPrimaryOptions();
  }, []);

  const handleIcdDropdownChange = (e) => {
    setFormData({
      ...formData,
      primaryICD: e.value,
    });
  };

  const handleDropdownChange = (e) => {
    const selectedPrimary = primaryOptions.find(
      (item) => item.value === e.value
    );

    setFormData({
      ...formData,
      primary: selectedPrimary.value,
      primaryICD: selectedPrimary.termCode,
    });
  };
  const handleDropdownChangesecondary = (e) => {
    const selectedSecondary = secondaryOptions?.find(
      (item) => item.value === e.value
    );

    setFormData({
      ...formData,
      secondary: selectedSecondary.value,
      secondaryICD: selectedSecondary.termCode,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log(formData, "hbhbhb");

  const handleSubmit = async () => {
    try {
      const payload = {
        id: scheduledata.id || null,
        patientCode: scheduledata.patientCode || null,
        smartDoc: {
          data: {
            firstName: scheduledata.firstName || null,
            lastName: scheduledata.lastName || null,
            mobile: scheduledata.mobile || null,

            ePrescriptionGen: 1,
            updateePrescriptionStatus: 1,
            ePrescriptionStatus: 1,
            documentReceivedSource: scheduledata.documentReceivedSource || null,
            docName: formData.doctor,
            chiefComplaint: formData?.chiefComplaint,
            primaryDiagnosis: formData.primary || null,
            primarySymptoms: formData.primaryICD || null,
            recommendation: formData.recommendation || null,
            secondaryDiagnosis: formData.secondary || null,
            secondarySymptoms: formData.secondaryICD || null,
            symptoms: formData.symptoms || null,
            visitDate: formData.date || null,
            patientDrugPrescriptionList:
              submitData.patientDrugPrescriptionList?.map((item) => ({
                code: item?.code || null,
                composition: item.composition || null,
                afterBeforeFood: item?.duration || null,
                type: item.type || null,
                drugName: item.name || null,
                dosage: item?.dosage || null,
                quantity: item?.quantity || null,
                instuction: item?.instructions || null,
              })) || [],
            patientLabTestsList:
              submitData.patientLabTestsList?.map((test) => ({
                labTestId: test.code || null,
                labTestName: test.label || null,
                name: test.value || null,
              })) || [],
          },
        },
        modifiedBy: "rcFIA2639p",

        status: 1,
        smartReportStatus: 1,
        isVerified: 1,
      };
      const response = await http.put(
        `${process.env.REACT_APP_BASEURL}PatientDocument/updatePrescription`,
        payload
      );
      console.log(response, "sedrtfgyh");
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Prescription submitted successfully!",
        });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Failed",
          detail: "Unable to submit prescription.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong while submitting prescription.",
      });
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Toast ref={toast} />
      <button
        onClick={closeFun}
        style={{
          alignSelf: "flex-end",
          marginBottom: "10px",
          background: "red",
          color: "white",
          border: "none",
          borderRadius: "4px",
          padding: "5px 10px",
          cursor: "pointer",
        }}
      >
        Close
      </button>

      <div style={{ display: "flex", gap: "20px" }}>
        {/* Uploaded Prescription Section */}
        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <h3>Uploaded Manual Prescription</h3>
          {document?.toLowerCase().endsWith(".pdf") ? (
            <iframe
              src={scheduledata.originalDoc} // Use originalDoc URL for PDFs
              style={{
                width: "100%",
                height: "500px",
                borderRadius: "8px",
                marginTop: "10px",
              }}
              title="Prescription PDF"
            ></iframe>
          ) : (
            <img
              src={scheduledata.originalDoc} // Use originalDoc for the actual image URL
              alt="Prescription Document"
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
                marginTop: "10px",
              }}
            />
          )}
        </div>

        {/* e-Prescription Section */}
        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "10px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
            Convert Manual Prescription to e-Prescription
          </h3>
          <div style={{ marginBottom: "10px" }}>
            <strong>Patient:</strong> {firstName} {lastName}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Mobile:</strong> {mobile}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <strong>Patient Code:</strong> {patientCode}
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <div style={{ marginBottom: "10px" }}>
              <label>Chief Complaint</label>
              <input
                type="text"
                name="chiefComplaint"
                value={formData.chiefComplaint}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <div style={{ flex: 1 }}>
                <label>Doctor</label>
                <input
                  type="text"
                  name="doctor"
                  value={formData.doctor}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginTop: "5px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label>Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginTop: "5px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <div style={{ flex: 1 }}>
                <label>Primary</label>
                <Dropdown
                  value={formData?.primary}
                  options={primaryOptions}
                  onChange={handleDropdownChange}
                  placeholder="Select Primary"
                  style={{
                    width: "100%",
                    marginTop: "5px",
                  }}
                  panelStyle={{
                    maxWidth: "100%",
                  }}
                  scrollHeight="200px"
                />
              </div>
              <div style={{ flex: 1 }}>
                <label>ICD-10 Code</label>
                <input
                  type="text"
                  value={formData.primaryICD}
                  readOnly
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginTop: "5px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    background: "#f5f5f5",
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <div style={{ flex: 1 }}>
                <label>Secondary</label>
                <Dropdown
                  value={formData?.secondary}
                  options={secondaryOptions}
                  onChange={handleDropdownChangesecondary}
                  placeholder="Select Secondary"
                  style={{
                    width: "100%",
                    marginTop: "5px",
                  }}
                  panelStyle={{
                    maxWidth: "100%",
                  }}
                  scrollHeight="200px"
                />
              </div>
              <div style={{ flex: 1 }}>
                <label>ICD-10 Code</label>
                <input
                  type="text"
                  value={formData.secondaryICD}
                  readOnly
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginTop: "5px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    background: "#f5f5f5",
                  }}
                />
              </div>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <label>Symptoms</label>
              <input
                type="text"
                name="symptoms"
                value={formData.symptoms}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <div style={{ marginBottom: "10px" }}>
              <label>Recommendation</label>
              <textarea
                name="recommendation"
                value={formData.recommendation}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                type="button"
                onClick={() => setActiveModal("medicine")} // Open Medicine Modal
                style={{
                  background: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Add Medicine
              </button>
              <button
                type="button"
                onClick={() => setActiveModal("lab")} // Open Lab Modal
                style={{
                  background: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Add Lab Test
              </button>
            </div>

            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <button
                type="button"
                onClick={handleSubmit}
                style={{
                  background: "blue",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        header="Add Medicine"
        visible={activeModal === "medicine"}
        onHide={() => setActiveModal(null)}
      >
        <MedicineModal
          onClose={() => setActiveModal(null)}
          setMedicineData={handleMedicineData}
        />
      </Dialog>

      {/* Lab Modal */}
      <Dialog
        header="Add Lab Test"
        visible={activeModal === "lab"}
        onHide={() => setActiveModal(null)}
      >
        <LabModal
          onClose={() => setActiveModal(null)}
          setLabData={handleLabData}
        />
      </Dialog>
    </div>
  );
}

export default SidebarEprescription;
